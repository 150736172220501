import { SHARED_MODEL, api } from ":mods";
import { ENDPOINTS } from "../const";
import { ProfileDetailsProps } from "../models";

export async function getProfileDetails(id: string): Promise<SHARED_MODEL.ApiResponseSchema<ProfileDetailsProps>> {
  return api
    .getAuth(ENDPOINTS.get_profile_details.replace("{id}", id))
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function updateProfileDetials(id: string, data: ProfileDetailsProps) {
  return api
    .putAuth(ENDPOINTS.get_profile_details.replace("{id}", id), data)
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function updateProfileImage(data) {
  return api
    .putAuth(ENDPOINTS.update_profile_image, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
